import React from 'react';
// import sections
import AtomeIntro from '../components/sections/AtomeIntro';
import AtomeJoin from '../components/sections/AtomeJoin';
import AtomeFeatures from '../components/sections/AtomeFeatures';
import AtomeDemo from '../components/sections/AtomeDemo';
import AtomeInstall from '../components/sections/AtomeInstall';
import AtomeBuilt from '../components/sections/AtomeBuilt';
import Cta from '../components/sections/Cta';

const atomeOS = () => {

  return (
    <>
      <title>atomeOS - Swattle</title>
      <AtomeIntro className="illustration-section-01" />
      <AtomeJoin topDivider />
      <AtomeFeatures />
      <AtomeDemo invertMobile topDivider imageFill className="illustration-section-02" />
      <AtomeInstall topDivider bottomDivider />
      <AtomeBuilt topDivider bottomDivider />
      <Cta split />
    </>
  );
}

export default atomeOS;
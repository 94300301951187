import React from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import ButtonGroup from '../elements/ButtonGroup';
import Button from '../elements/Button';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

const Join = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {

  const outerClasses = classNames(
    'hero section center-content',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'hero-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container-sm">
        <div className={innerClasses}>
          <div className="hero-content">
            <h2 id="join_us" className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="200">
              Want to <span className="text-color-primary">work with us?</span>
            </h2>
            <div className="container-xs">
              <p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="400">
                We're looking for highly motivated volunteers who're willing to get accustomed to our values and share our principles. Depending upon your commitment and work ethics, you'll also get a chance to be pushed onto the core team. On failing to contribute for a long time, you'll be revoked of your volunteership. <br></br>
                <br></br> Mail us your resume and the position you want to fill in. After shortlisting, we'll contact you and select based on a telephonic interview followed by an online interview. <br></br>
                <br></br> Unfortunately, due to the overwhelming volume of applications we receive, we need some time to process all of them. We cannot reconsider applications for the next six months after they've been rejected.
              </p>
              <div className="reveal-from-bottom" data-reveal-delay="600">
                <ButtonGroup>
                  <Button tag="a" color="primary" wideMobile href="mailto:jobs@discoverswattle.com" target="_blank">
                    Join us as a Volunteer
                  </Button>
                </ButtonGroup>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

Join.propTypes = propTypes;
Join.defaultProps = defaultProps;

export default Join;
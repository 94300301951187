import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import Image from '../elements/Image';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}
const FeaturesPartners = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {

  const outerClasses = classNames(
    'features-tiles section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-tiles-inner section-inner pt-0',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames(
    'tiles-wrap center-content',
    pushLeft && 'push-left'
  );

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <div className="container-sm">
            <div className="hero-inner section-inner">
              <div className="hero-content">
                <h1 className="mt-0 mb-16 reveal-from-bottom center-content" data-reveal-delay="200">
                  Our <span className="text-color-primary">Partners</span>
                </h1>
                <div className="container-xs">
                  <p className="m-0 mb-32 reveal-from-bottom center-content" data-reveal-delay="400">
                    It doesn't matter where you're going, its who you've beside you. We're proud to present communities with whom we share our perspectives and principles. <br></br>
                    <br></br> Click on their logo to know more.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className={tilesClasses}>

          <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="mb-16">
                    <a href="https://www.gnome.org" target="_blank" rel="noopener noreferrer">
                      <Image
                        src={require('./../../assets/images/gnome-logo.png')}
                        alt=""
                        width={125}
                        height={125} />
                    </a>
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8 text-color-primary">
                    GNOME
                  </h4>
                  <p className="m-0 text-sm">
                    A free and open-source desktop environment for Unix-like operating systems.
                  </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="mb-16">
                    <a href="https://nmf.earth" target="_blank" rel="noopener noreferrer">
                      <Image
                        src={require('./../../assets/images/nmfearth-logo.png')}
                        alt=""
                        width={150}
                        height={150} />
                    </a>
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8 text-color-primary">
                    NMF.earth
                  </h4>
                  <p className="m-0 text-sm">
                    Fighting climate breakdown and biodiversity loss by calculating and mitigating your CO2 emissions. Finding solutions to reduce your waste, and transition to a sustainable life.
                  </p>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

FeaturesPartners.propTypes = propTypes;
FeaturesPartners.defaultProps = defaultProps;

export default FeaturesPartners;
import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import Image from '../elements/Image';
import ButtonGroup from '../elements/ButtonGroup';
import Button from '../elements/Button';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}
const FeaturesProducts = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {

  const outerClasses = classNames(
    'features-tiles section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-tiles-inner section-inner pt-0',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames(
    'tiles-wrap center-content',
    pushLeft && 'push-left'
  );

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <div className="container-sm">
            <div className="hero-inner section-inner">
              <div className="hero-content">
                <h1 className="mt-0 mb-16 reveal-from-bottom center-content" data-reveal-delay="200">
                  Our <span className="text-color-primary">Products</span>
                </h1>
                <div className="container-xs">
                  <p className="m-0 mb-32 reveal-from-bottom center-content" data-reveal-delay="400">
                    All our download links are GitHub based. We neither track your usage nor attach malware. We follow security policies strictly.  <br></br>
                    <br></br> To download our products and get hassle-free experience, bypass all the browser and system warnings.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className={tilesClasses}>

          <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="mb-16">
                    <a href="https://mergeurl.com" target="_blank" rel="noopener noreferrer">
                      <Image
                        src={require('./../../assets/images/mergeurl-logo.png')}
                        alt=""
                        width={96}
                        height={96} />
                    </a>
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8 text-color-primary">
                    MergeURL
                  </h4>
                  <p className="m-0 text-sm">
                    A web application that helps you merge and shorten multiple URLs hassle-free without any user registration. Product discontinued since August 2022.
                  </p>
                  <ButtonGroup className="m-8">
                    <Button tag="a" color="primary" wideMobile href="https://mergeurl.netlify.app" target="_blank">
                        Visit
                    </Button>
                  </ButtonGroup>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="mb-16">
                    <a href="https://github.com/saivittalb/nowspot" target="_blank" rel="noopener noreferrer">
                      <Image
                        src={require('./../../assets/images/nowspot-logo.png')}
                        alt=""
                        width={96}
                        height={96} />
                    </a>
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8 text-color-primary">
                    NowSpot
                  </h4>
                  <p className="m-0 text-sm">
                    A macOS application which enables Spotify to be managed from the menu bar by providing song notifications and playback control.
                  </p>
                  <ButtonGroup className="m-8">
                    <Button tag="a" color="primary" wideMobile href="https://github.com/saivittalb/nowspot/releases/download/v1.2/Nowspot.app.zip" target="_blank">
                        Download
                    </Button>
                  </ButtonGroup>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="mb-16">
                    <a href="https://github.com/saivittalb/latencer" target="_blank" rel="noopener noreferrer">
                      <Image
                        src={require('./../../assets/images/latencer-logo.png')}
                        alt=""
                        width={96}
                        height={96} />
                    </a>
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8 text-color-primary">
                    Latencer
                  </h4>
                  <p className="m-0 text-sm">
                    A macOS application which displays your internet latency in the menu bar.
                  </p>
                  <ButtonGroup className="m-8">
                    <Button tag="a" color="primary" wideMobile href="https://github.com/saivittalb/latencer/releases/download/v1.2/Latencer.app.zip" target="_blank">
                        Download
                    </Button>
                  </ButtonGroup>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

FeaturesProducts.propTypes = propTypes;
FeaturesProducts.defaultProps = defaultProps;

export default FeaturesProducts;
import React from 'react';
// import sections
import FeatureProduct from '../components/sections/FeatureProducts';
import Cta from '../components/sections/Cta';

const Products = () => {

  return (
    <>
      <title>Products - Swattle</title>
      <FeatureProduct className="illustration-section-01" />
      <Cta split />
    </>
  );
}
  
export default Products;
import React from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import ButtonGroup from '../elements/ButtonGroup';
import Button from '../elements/Button';
import Image from '../elements/Image';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

const Profile = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {

  const outerClasses = classNames(
    'hero section center-content',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'hero-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container-sm">
        <div className={innerClasses}>
          <div className="hero-content">
            <h1 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="200">
              Our <span className="text-color-primary">Leadership</span>
            </h1>
            <div className="container-xs">
              <br></br>
              <Image
                  src={require('./../../assets/images/founder.png')}
                  width={200}
                  height={200} />
              <br></br>
              <h3 className="m-0 mb-32" data-reveal-delay="400">
                <span className="text-color-primary">Sai Vittal Battula</span>
                <br></br> Founder
              </h3>
              <div data-reveal-delay="600">
                <ButtonGroup>
                  <Button tag="a" color="primary" wideMobile href="https://saivittalb.com" target="_blank" rel="noopener noreferrer">
                    Website
                  </Button>
                  <Button tag="a" color="primary" wideMobile href="https://linkedin.com/in/saivittalb" target="_blank" rel="noopener noreferrer">
                    LinkedIn
                  </Button>
                  <Button tag="a" color="primary" wideMobile href="https://github.com/saivittalb/" target="_blank" rel="noopener noreferrer">
                    GitHub
                  </Button>
                </ButtonGroup>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

Profile.propTypes = propTypes;
Profile.defaultProps = defaultProps;

export default Profile;
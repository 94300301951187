import React from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import ButtonGroup from '../elements/ButtonGroup';
import Button from '../elements/Button';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

const AtomeIntro = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {

  const outerClasses = classNames(
    'hero section center-content',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'hero-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container-sm">
        <div className={innerClasses}>
          <div className="hero-content">
            <h1 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="200">
              <span className="text-color-primary">More efficient</span> and <span className="text-color-primary">less power consuming</span> operating system
            </h1>
            <div className="container-xs">
              <p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="400">
               atomeOS enables eco-friendly computing out of the box to help reduce your carbon footprint by prolonging battery life, reducing resource load and power usage, and can even revive older computers without sacrificing the aesthetics or performance of the operating system. <br></br>
               <br></br> Have problems installing? Refer our <a href="#install">quick installation guide</a> or check our documentation. <br></br>
               <br></br> Scroll down to view features, demo and <a href="#build-process">how it's built</a>.
              </p>
              <div data-reveal-delay="600">
                <ButtonGroup>
                  <Button tag="a" color="primary" wideMobile href="https://mega.nz/file/auYBFa7C#jqBenagbca1cTziBtYkmSnPN_RjQC2DWCsOkOI1_I6o" target="_blank" rel="noopener noreferrer">
                    Download (64-bit)
                  </Button>
                  <Button tag="a" color="primary" wideMobile href="https://github.com/swattle/atomeOS-documentation/blob/master/Compare_with_Other_OSs.md" target="_blank" rel="noopener noreferrer">
                    Compare with other OSs
                  </Button>
                  <Button tag="a" color="primary" wideMobile href="https://github.com/swattle/atomeOS-documentation/wiki" target="_blank" rel="noopener noreferrer">
                    Documentation
                  </Button>
                </ButtonGroup>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

AtomeIntro.propTypes = propTypes;
AtomeIntro.defaultProps = defaultProps;

export default AtomeIntro;
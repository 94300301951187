import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}
const AtomeFeatures = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {

  const outerClasses = classNames(
    'features-tiles section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-tiles-inner section-inner pt-0',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames(
    'tiles-wrap center-content',
    pushLeft && 'push-left'
  );

  const sectionHeader = {
    title: 'Features',
    paragraph: 'Built on one of the most secure platforms and designed to be speedy. Companies like Microsoft, Google, Samsung, Red Hat, Intel, and Oracle support Linux which means they support our atomeOS by continuously providing fixes. As a home user, you benefit from these continuous contributions by developers in terms of usability, security, and customization.'
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={tilesClasses}>

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8 text-color-primary">
                    Swift
                  </h4>
                  <p className="m-0 text-sm">
                    Every detail of atomeOS is crafted to achieve maximum performance. This extraordinary factor allows the user to get the fastest experience with low power consumption whether you're running high-end applications, gaming, or just browsing.
                  </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8 text-color-primary">
                    Secure
                  </h4>
                  <p className="m-0 text-sm">
                    atomeOS, like other members of the Linux family, is secured to a high-level and immune to viruses and malware. It utilizes the same Linux kernel which powers the International Space Station. Frequent updates make it even more secure.
                  </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8 text-color-primary">
                    Non-proprietary
                  </h4>
                  <p className="m-0 text-sm">
                    Unlike macOS and Windows, which are proprietary, atomeOS is open-sourced. Every component in our OS has been peer-reviewed by members of the open-source community. Release patches by The Linux Foundation automatically merge into our codebase.
                  </p>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

AtomeFeatures.propTypes = propTypes;
AtomeFeatures.defaultProps = defaultProps;

export default AtomeFeatures;
import React from 'react';
// import sections
import Profile from '../components/sections/Profile';
import Cta from '../components/sections/Cta';

const Leadership = () => {

  return (
    <>
      <title>Leadership - Swattle</title>
      <Profile className="illustration-section-01" />
      <Cta split />
    </>
  );
}
  
export default Leadership;
import React from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

const AtomeBuilt = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {

  const outerClasses = classNames(
    'hero section center-content',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'hero-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container-sm">
        <div className={innerClasses}>
          <div className="hero-content">
            <h2 id="build-process" className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="200">
              How its <span className="text-color-primary">built</span>
            </h2>
            <div className="container-xs">
              <p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="400">
                The idea stroke up in 2018 and development began in early 2019 by our founder, Vittal. <br></br> 
                <br></br> First, a base Linux kernel was setup attached with Ubuntu GUI so that the command line package manager(apt and snap) can be used. Using this command line package manager, the OS was connected to several online internet repositories to install lightweight, open-source, 
                free alternatives to common power-hungry desktop applications, as well as a few lightweight desktop components such as a display manager, login screen, panel, dock, and a window manager. Lubuntu's settings were also imported to a certain extent. <br></br>
                <br></br> The next step was to create a desktop configuration file that would save our “desktop environment” so that it would be accessible across all users. 
                To do this, <a href="https://www.freedesktop.org" target="_blank" rel="noopener noreferrer">freedesktop.org</a> standards were complied, and in the end, the OS had a functional GUI and suite of apps, built from the ground up to be as lightweight and energy-efficient as possible. <br></br>
                <br></br> From here on, the actual development process started. To achieve more efficiency, scripts were placed in the kernel that run a set of shell commands to install and try all the possible configurations (fonts, wallpaper, and themes) during initial boot depending on the hardware. 
                These scripts return the least amount of time taken by a particular configuration and push those into the default settings. This way ensures that the OS has achieved efficiency to its utmost capability. Developing these scripts wasn't easy. It was a manual process with no guides available. 
                This process consumed a lot of time as any wrong command executed by the script would trigger an unexpected output and the OS would go corrupt and the process should be implemented again right from the scratch. Thousand of such cycles led to the development of the current version. <br></br>
                <br></br> Once the OS got stable, new algorithms were super-imposed on the kernel which modifies the multi-threading and multi-processing capabilities depending on the processor and hardware. Later on, GRUB (boot loader package) was adjusted to accommodate the modified scripts. <br></br>
                <br></br> After fixing critical errors on testing, the OS was finally ready to be shipped. The final patch was packed into a bootable ISO image using Perl scripts which utilized isolinux. <br></br>
                <br></br> Currently, the OS uses LXQt desktop environment as a default and is shipped under Ubuntu's name since it's GUI is a derviative of Ubuntu. atomeOS codenames refer to its GUI derviative codename of Ubuntu.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

AtomeBuilt.propTypes = propTypes;
AtomeBuilt.defaultProps = defaultProps;

export default AtomeBuilt;
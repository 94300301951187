import React from 'react';
// import sections
import FeaturePartner from '../components/sections/FeaturePartner';
import Cta from '../components/sections/Cta';

const Partners = () => {

  return (
    <>
      <title>Partners - Swattle</title>
      <FeaturePartner className="illustration-section-01" />
      <Cta split />
    </>
  );
}
  
export default Partners;
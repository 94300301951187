import React from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

const AtomeJoin = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {

  const outerClasses = classNames(
    'hero section center-content',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'hero-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container-sm">
        <div className={innerClasses}>
          <div className="hero-content">
            <h2 id="join_us" className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="200">
              Why you should migrate to <span className="text-color-primary">atomeOS</span>
            </h2>
            <div className="container-xs">
              <p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="400">
                atomeOS will run fine with only half a gigabyte of memory and a 6 GB hard drive, and it works with any computer processor that's 64-bit. In short, that means you can revive almost any old PC, dating back to 2008. 
                Installing atomeOS on an old PC saves that computer from becoming electronic waste (or e-waste). <br></br>
                <br></br> The greatest limiting factor for a PC's lifespan is wear and tear to its CPU processor from heavy use, as well as damage to internal components from heat. atomeOS uses 9% of the memory that Windows does, and 25% of the CPU usage. 
                At idle, atomeOS barely uses 400 MB of system memory and has a low CPU load. By comparison, Windows at idle uses anywhere between 2 to 4 GB of memory and regularly spikes in CPU load. The result is a computer that stays performant over time as well as the extension of the computer's lifetime. <br></br>
                <br></br> In fact, the average Windows laptop generates between 44 and 88 kg of CO2 a year, while the average Windows PC generates 175 kg - almost 2% of the carbon emissions of the entire country of Belgium. By switching to atomeOS, those numbers fall to only 44 kg for the desktop and 11-22 kg for the laptop.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

AtomeJoin.propTypes = propTypes;
AtomeJoin.defaultProps = defaultProps;

export default AtomeJoin;
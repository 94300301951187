import React from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

const AtomeInstall = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {

  const outerClasses = classNames(
    'hero section center-content',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'hero-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container-sm">
        <div className={innerClasses}>
          <div className="hero-content">
            <h2 id="install" className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="200">
              <span className="text-color-primary">Quick Installation</span> Guide
            </h2>
            <div className="container-xs">
              <p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="400">
                You'll need <a href="https://www.balena.io/etcher/" target="_blank" rel="noopener noreferrer">balenaEtcher</a> to write the ISO image. Next, insert a USB with at least 4 GB of storage into your computer. 
                Launch balenaEtcher, select the ISO image, and write it to your USB. <br></br>
                <br></br> Restart your computer. As it boots up again, hit your computer's BIOS key to enter the BIOS. This key varies by computer, but normally it's either Esc or one of the function keys. 
                Once you're into the BIOS, choose to boot from the USB. <br></br>
                <br></br> Soon, you'll be greeted with the default atomeOS desktop. You can play around with the desktop live, but no changes you make will be saved to your computer. 
                Once you're ready to install, double click the install icon on the desktop, and you'll be greeted with the installation wizard. Follow the steps carefully.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

AtomeInstall.propTypes = propTypes;
AtomeInstall.defaultProps = defaultProps;

export default AtomeInstall;
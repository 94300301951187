import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const AtomeDemo = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {

  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  const sectionHeader = {
    title: 'Demo',
    paragraph: "Inspired by macOS design and co-powered by Ubuntu's GUI."
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={splitClasses}>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Desktop
                </div>
                <p className="m-0">
                  Along the top of the screen runs the Top Panel. On the left, it contains a global menu, where a lot of the functions for many applications are housed. On the right, you've access to a system tray, with icons for controlling different settings and applications. Finally, on the far right, there's a clock displaying the time, and a power button, to logout, shutdown, suspend, and otherwise end your session. The bottom of the screen houses a dock. The dock lets you quickly launch applications, and it'll also display all the currently open applications. On the far left end of the dock, a rocket-ship icon launches the App Launcher. 
                </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/features-desktop.png')}
                  alt="Features split 01"
                  width={528}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  App Launcher
                </div>
                <p className="m-0">
                  The App Launcher is a full-screen app launcher. You can search for programs you've installed by their name, and also scroll through the list to find the app you want to launch. Hit escape to exit. Sometimes, you want to just quickly launch an app, perform a calculation, search Wikipedia, or any other number of functions. Hitting Ctrl + Space or Cmd + Space (on Mac) on your keyboard activates Spotlight, a simple application that'll let you instantly perform common functions from your keyboard via search.
                </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/features-launchpad.png')}
                  alt="Features split 02"
                  width={528}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Low memory consumption
                </div>
                <p className="m-0">
                  atomeOS uses 9% of the memory that Windows does, and 25% of the CPU usage. The result is a computer that stays performant over timekeeping itself out of the e-waste bin for far longer. In return, the GPU's and CPU's power consumption would be decreased roughly by four times. It directly correlates to reduced carbon in the atmosphere, since most electricity is still generated by fossil fuel burning power-plants.
                </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/features-activity-panel.png')}
                  alt="Features split 03"
                  width={528}
                  height={396} />
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

AtomeDemo.propTypes = propTypes;
AtomeDemo.defaultProps = defaultProps;

export default AtomeDemo;